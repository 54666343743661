<template>
  <nav>
    <router-link to="/"
      ><i style="color: #000" class="bi bi-house-fill"></i
    ></router-link>
    |
    <router-link to="/demand"
      ><button class="btn btn-outline-dark btn-sm">
        Demandez
      </button></router-link
    >
    |
    <router-link to="/pay"
      ><button class="btn btn-outline-success btn-sm">
        Paiements
      </button></router-link
    >
    |
    <router-link to="/about"
      ><button class="btn btn-dark btn-sm">
        À propos de nous
      </button></router-link
    >
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #eaeaea;
  height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
