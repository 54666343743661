<template>
  <div class="container">
    <div class="card">
      <div class="row" style="padding: 5px">
        <div class="col-lg-4 offset-lg-4">
          <img
            src="@/assets/logo.png"
            width="300"
            style="margin: 10px"
            alt=""
          />
          <hr />
          <h5>
            Effectuez de petits paiements quotidiens et vous pourrez commencer à
            utiliser la moto immédiatement.
          </h5>
          <img alt="Moto logo" src="../assets/bajaj.png" style="width: 70%" />
          <br />
          <br />
          <h5>Après un an, la moto est à vous !</h5>

          <router-link to="/about"
            ><button style="margin: 10px" class="btn btn-warning btn-lg">
              <strong>Plus d'infos</strong>
            </button></router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <hr />
          <small
            >Moto Gan Gan est un effort caritatif rendu possible par
            <a href="#">Renforcer les Fondations de l'Afrique - ONG</a></small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
